* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    width: 100%;
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
}

body,
header,
footer,
main {
    width: 100%;
    position: relative;
    display: block;
    font-family: "Montserrat", "Helvetica Neue", "Arial", sans-serif;
    font-size: 1rem;
    line-height: 1.5;
}

p {
    font-size: 1rem;
    line-height: 1.5;
    font-family: "Montserrat", "Helvetica Neue", "Arial", sans-serif;
}

img {
    width: 100%;
    height: auto;
    max-height: 100%;
}

a {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
}

.bannerImage {
    width: 100%;
    height: auto;
    object-fit: cover;
}

@media (min-width: 768px) {
    .bannerImage {
        height: 200px;
    }
}

@media (min-width: 1024px) {
    .bannerImage {
        height: 400px;
    }
}

.bannercrop .bannerImage {
    height: 100px;
}

@media (min-width: 568px) {
    .bannercrop .bannerImage {
        height: 150px;
    }
}

@media (min-width: 768px) {
    .bannercrop .bannerImage {
        height: 240px;
    }
}

@media (min-width: 1024px) {
    .bannercrop .bannerImage {
        height: 300px;
    }
}

@media (min-width: 1366px) {
    .bannercrop .bannerImage {
        height: 400px;
    }
}

#header,
#headerLeft {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

#header {
    padding: 1rem;
}

@media (min-width: 768px) {
    #header {
        padding: 2rem;
    }
}

@media (min-width: 1280px) {
    #header {
        padding: 2rem 6rem;
    }
}

#headerLeft,
#headerRight {
    width: 90%;
}

@media (min-width: 768px) {
    #headerLeft {
        width: 31%;
    }
}

#headerLeft {
    justify-content: flex-start;
}

#headerMid {
    width: 10%;
    text-align: center;
}

@media (min-width: 768px) {
    #headerMid {
        width: 68%;
    }
}

#headerRight {
    text-align: right;
}

#siteLogo {
    width: 30%;
}

@media (min-width: 768px) {
    #siteLogo {
        width: 35%;
    }
}

#siteLogo img {
    width: 100%;
    height: auto;
}

@media (min-width: 1024px) {
    #siteLogo img {
        width: 80%;
    }
}

#siteName {
    width: 65%;
}

#siteAbbr {
    font-size: 1.1rem;
    font-weight: bold;
}

@media (min-width: 768px) {
    #siteAbbr {
        font-size: 1.2rem;
    }
}

@media (min-width: 1024px) {
    #siteAbbr {
        font-size: 1.6rem;
    }
}

#siteMenu {
    display: none;
}

@media (min-width: 768px) {
    #siteMenu {
        display: block;
    }
}

#siteMenuMobile {
    display: block;
}

@media (min-width: 768px) {
    #siteMenuMobile {
        display: none;
    }
}

#siteFull {
    opacity: .8;
}

@media (min-width: 1024px) {
    #siteFull {
        text-transform: uppercase;
    }
}

#footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    padding: 1rem;
    background-color: #0f1732;
    color: #fff;
    height: 100px;
    align-content: center;
}

@media (min-width: 768px) {
    #footer {
        padding: 2rem 4rem;
    }
}

@media (min-width: 1024px) {
    #footer {
        padding: 2rem 6rem;
    }
}

#footer::before {
    content: '';
    background-image: url(../images/cmarc-banner-1920.jpg);
    background-position: 50% 82%;
    background-size: cover;
    background-position-y: bottom;
    filter: brightness(0.2);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    font-family: "Open Sans", "Helvetica Neue", "Arial", sans-serif;
}

#footerLeft,
#footerRight {
    width: 100%;
    filter: brightness(1);
    display: block;
    align-items: center;
}

@media (min-width: 768px) {

    #footerLeft,
    #footerRight {
        width: 50%;
        display: flex;
    }
}

#footerLeft {
    text-align: center;
    justify-content: flex-start;
    order: 2;
    margin-top: 0.5rem;
}

#footerRight {
    text-align: center;
    justify-content: flex-end;
    align-items: center;
    order: 1;
}

@media (min-width: 768px) {
    #footerLeft {
        order: 1;
        text-align: left;
        margin-top: 0;
    }

    #footerRight {
        order: 2;
        text-align: right;
    }
}

#footerRight ul,
#siteSocials ul {
    display: inline-flex;
    list-style-type: none;
    gap: 1.5rem;
    align-items: center;
}

@media (min-width: 768px) {

    #footerRight ul,
    #siteSocials ul {
        gap: 2.5rem;
    }
}

.social-icon {
    width: 32px;
    height: auto;
    filter: invert();
}

#siteSocials .social-icon {
    filter: none;
}

#siteNav {
    width: 100%;
    display: inline-flex;
    gap: 1rem;
    list-style-type: none;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-family: "Open Sans", "Helvetica Neue", "Arial", sans-serif;
}

@media (min-width: 1024px) {
    #siteNav {
        font-size: 1.3rem;
    }
}

#siteNav li {
    padding: 1rem 0;
}

#siteNav li:not(.menucurrent):hover,
#siteNav li:active,
.menucurrent {
    border-bottom: 2px solid #00666d;
}

#homequote {
    width: 100%;
    padding: 1rem;
    text-align: center;
    margin: 0 auto;
}

@media (min-width: 768px) {
    #homequote {
        width: 100%;
        padding: 2rem;
    }
}

@media (min-width: 1024px) {
    #homequote {
        width: 85%;
        padding: 2rem 6rem;
    }
}

#homequote blockquote {
    font-size: 1.2em;
    font-weight: 300;
    line-height: 1.5;
    font-family: "Open Sans", "Helvetica Neue", "Arial", sans-serif;
}

@media (min-width: 768px) {
    #homequote blockquote {
        font-size: 1.382em;
        line-height: 1.618;
    }
}

blockquote:before,
blockquote:after {
    content: '"';
    float: left;
    margin-right: 0.25em;
    line-height: .9;
    font-size: 1.618em;
    opacity: .5;
}

blockquote:after {
    float: right;
}

#homequote cite {
    font-weight: 400;
    font-size: 1.05rem;
    font-family: "Open Sans", "Helvetica Neue", "Arial", sans-serif;
    font-style: normal;
}

@media (min-width: 768px) {
    #homequote cite {
        font-size: 1.2rem;
    }
}

hr {
    width: 80%;
    padding: 1px 0;
    margin: 10px auto;
    height: 2px;
    background-image: linear-gradient(to right, #00666d 40%, rgba(255, 255, 255, 0) 10%);
    background-size: 10px 100%;
    line-height: 1.618;
    border: 0;
}

hr.full {
    width: 100%;
}

#mainContent {
    width: 100%;
    margin: 0 auto;
    padding: 2rem;
}

@media (min-width: 1024px) {
    #mainContent {
        width: 80%;
        padding: 2rem 0;
    }
}

.buttonLarge {
    width: 100%;
    margin: 1rem auto;
    padding: 0.5rem;
    color: #fff;
    background-color: #00666d;
    text-align: center;
}

@media (min-width: 1024px) {
    .buttonLarge {
        width: 40%;
        margin: 2rem auto;
        padding: 1rem;
    }
}

.buttonLarge div:nth-of-type(1) {
    font-weight: bold;
    font-size: 1.2rem;
}

.buttonLarge img {
    width: 15px;
    height: auto;
    margin-right: 1rem;
}

.buttonLarge:focus,
.buttonLarge:hover {
    outline: thin dashed;
}

.tiles {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    justify-content: center;
    align-content: center;
    margin: 5rem 0;
}

.tile {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;
}

@media (min-width: 768px) {
    .tile {
        width: 32%;
    }
}

.tile a {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.tileTitle {
    font-size: 1.618em;
    font-family: "Montserrat", "Helvetica Neue", "Arial", sans-serif;
    font-weight: bold;
}

.tileSpacer {
    width: 40%;
    margin: 1rem auto;
    background-color: #000;
    height: 2px;
}

.tileText {
    font-size: 1.2rem;
    margin: 1rem 0;
}

.tileButton {
    width: auto;
    padding: 0.5rem 1rem;
    border: 2px solid #00666d;
    font-size: 1.1rem;
}

.tileButton:hover {
    background-color: #00666d;
    color: #fff;
}

.socialcards {
    width: 100%;
    padding: 2rem;
    background-color: #00666d;
    color: #fff;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    margin: 2rem 0;
}

.socialcard {
    width: 100%;
    background-color: #fff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    color: #000;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 2rem;
}

@media (min-width: 768px) {
    .socialcard {
        width: 30%;
    }
}

.socialcardImage {
    width: 100%;
    text-align: center;
}

.socialcardImage img {
    width: 30%;
    height: auto;
    margin: 0 auto;
}

.socialcardTitle {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
}

.socialcardText {
    font-size: 1.1rem;
    margin: 1rem 0;
    text-align: center;
}

.tileImage {
    width: 100%;
    text-align: center;
}

.tileImage img {
    width: 50px;
    height: auto;
}

.banner {
    width: 100%;
    position: relative;
}

.overlay {
    display: none;
}

.banneroverlay .overlay {
    background: linear-gradient(135deg, #4aeadc, #9778d1 20%, #cf2aba 40%, #ee2c82 60%, #fb6962 80%, #fef84c);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: block;
    opacity: 0.3;
}

.textoverlay {
    text-transform: capitalize;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: block;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: #fff;
    font-weight: bold;
}

@media (min-width: 768px) {
    .textoverlay {
        font-size: 4rem;
    }
}

.text-center {
    text-align: center;
}

.campaign {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.campaignImage {
    width: 100%;
}

@media (min-width: 768px) {
    .campaignImage {
        width: 24%;
    }
}

.campaignContent {
    width: 100%;
}

@media (min-width: 768px) {
    .campaignContent {
        width: 74%;
    }
}

.campaignTitle {
    font-size: 2rem;
    color: #00666d;
    font-weight: bold;
    font-family: "Open Sans", "Helvetica Neue", "Arial", sans-serif;
}

.campaignText {
    font-family: "Open Sans", "Helvetica Neue", "Arial", sans-serif;
}

.strong {
    font-weight: bold;
}

#newsarticles {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.article {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    margin-bottom: 2rem;
}

@media (min-width: 1024px) {
    .article {
        width: 32%;
        margin-bottom: 0;
    }
}

@media (min-width: 1280px) {
    .article {
        width: 29%;
        margin-bottom: 0;
    }
}

.articleName {
    font-size: 1.1rem;
    font-weight: bold;
    color: #00666d;
    padding: 1rem;
}

.articleDate {
    padding: 0 1rem 1rem;
}

.articleImage {
    height: auto;
    width: 100%;
    object-fit: contain;
    border: 1px solid #00666d;
}

@media (min-width: 1024px) {
    .articleImage {
        height: 200px;
    }
}

@media (min-width: 1280px) {
    .articleImage {
        height: 200px;
    }
}

@media (min-width: 1366px) {
    .articleImage {
        height: 225px;
    }
}

@media (min-width: 1680px) {
    .articleImage {
        height: 300px;
    }
}

.articleImage img {
    width: auto;
    max-width: 100%;
    height: 100%;
}

.team {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.teamMember {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

@media (min-width:768px) {
    .teamMember {
        width: 48%;
    }
}

.teamMemberImage {
    width: 100%;
}

.teamMemberImage img {
    width: 40%;
    height: auto;
    border-radius: 100%;
}

.teamMemberName {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
}

.teamMemberTitle {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

hr.separator-about {
    width: 50%;
    border: 1px double black;
    background-color: transparent
}

.campaignHeadingBlock {
    padding: 1rem;
    background: #005359;
    color: #fff;
    margin: 1rem 0 1rem 0;
    font-size: 2rem;
    line-height: 1.5;
    font-weight: bold;
}

.campaignTextBlock {
    margin-bottom: 4rem;
}

.campaignTextBlock a,
a.campaignLink {
    color: #005359;
    text-decoration: underline;
    font-weight: bold;
}

.campaign-highlight {
    font-weight: bold;
    font-size: 1.2rem;
    color: #005359;
}

.campaignImageBlock {
    width: 100%;
    text-align: center;
}

.campaignImageBlock img {
    width: 60%;
}

.newsBlock {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    margin-top: 2rem;
}

.newsItem {
    width: 100%;
    margin-bottom: 4rem;
}

.newsSide {
    width: 100%;
}

@media (min-width: 768px) {
    .newsItem {
        width: 70%;
    }

    .newsSide {
        width: 25%;
    }
}

.newsTitle,
.newsSideHeading {
    font-size: 1.5rem;
    font-weight: bold;
}

.newsDate {
    font-size: 1rem;
    margin-bottom: 2rem;
}

.newsText p {
    font-size: 1.2rem;
    line-height: 1.5;
}

.newsImage {
    width: 100%;
}

.newsImage img {
    width: 80%;
    margin: 0 auto;
}

.newsSideItem {
    width: 100%;
    padding: 2rem 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.newsSideItem a {
    font-weight: bold;
    text-decoration: underline;
}

.contentBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap
}

.contentHeaderImage {
    width: 100%;
    text-align: center;
    margin-bottom: 4rem;
}

.contentHeaderImage img {
    width: 60%;
    margin: 0 auto;
}

.contentText {
    width: 80%;
    margin: 0 auto 4rem auto;
    font-size: 1.1rem;
    line-height: 1.5;
    text-align: center;
}

.contentArrow {
    width: 100%;
    text-align: center;
    margin-bottom: 4rem;
}

.contentArrow img {
    width: 8%;
    height: auto;
}

.contentRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.contentImage {
    width: 100%;
}

.contentImage img {
    width: 30%;
}

.contentDesc {
    width: 100%;
    text-align: left;
    font-size: 1.1rem;
    margin-bottom: 2rem;
}

@media (min-width: 768px) {
    .contentImage {
        width: 25%;
    }

    .contentDesc {
        width: 70%;
        font-size: 1.2rem;
        margin-bottom: 0;
    }

    .contentImage img {
        width: 100%;
    }
}

.contentDescTitle {
    font-weight: bold;
    font-size: 2rem;
}

.contentDescText {
    font-size: 1.2rem;
    line-height: 1.5;
}

.contentFooterImage,
.contentFooterText {
    width: 100%;
    text-align: center;
}

.contentFooterImage img {
    width: 15%;
}

.contentFooterText {
    font-size: 2rem;
    font-weight: bold;
}

#menuClick {
    width: auto;
    height: 100%;
    display: block;
    position: relative;
}

@media (min-width: 1024px) {
    #menuClick {
        display: none;
    }
}

#siteMenuMobile,
#siteMenuMobile:before,
#siteMenuMobile:After {
    position: absolute;
    content: '';
    width: 1.75rem;
    height: 0.156rem;
    background: #000;
    border-radius: 1.25rem;
    transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
    margin-left: -50%;
    cursor: pointer;
}

#siteMenuMobile {
    right: 12%;
}

#siteMenuMobile::before {
    top: -0.5rem;
}

#siteMenuMobile::after {
    top: 0.5rem;
}

#siteMenuMobile.active {
    background: transparent;
}

#siteMenuMobile.active::before,
#siteMenuMobile.active::after {
    background: #000;
    top: 0;
}

@media (min-width: 1024px) {

    #siteMenuMobile.active::before,
    #siteMenuMobile.active::after {
        background: #361e54;
        top: 0;
    }
}

#siteMenuMobile.active::before {
    transform: rotate(-225deg);
}

#siteMenuMobile.active::after {
    transform: rotate(225deg);
}

#mobileMenu {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: white;
}

#mobileMenu ul {
    list-style-type: none;
    display: block;
    padding: 1rem;
    margin: 0;
}

#mobileMenu ul li {
    height: 3rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #31589B;
}


#contactForm,
#contact-form {
    width: 100%;
    margin: 0 auto 4rem auto;
    display: block;
    position: relative;
}

@media (min-width: 1024px) {
    #contactForm {
        width: 60%;
    }
}

#contactForm label {
    width: 100%;
    height: 2rem;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: left;
}

#contactForm input {
    width: 100%;
    height: 2rem;
    font-size: 1.1rem;
    font-weight: normal;
    text-align: left;
    margin-bottom: 2rem;
}

#contactForm textarea {
    width: 100%;
    height: 10rem;
    margin-bottom: 2rem;
    font: inherit;
}

#contactForm select {
    width: 20%;
    font-size: 1.1rem;
    height: 2rem;
}

#contactForm button {
    width: 100%;
    height: 3rem;
    background-color: #31589B;
    color: #fff;
    border: 1px solid #000;
    font-size: 1.1rem;
    line-height: 1.1rem;
    cursor: pointer;
    margin-top: 2rem;
}

@media(min-width: 1024px) {
    #contactForm button {
        width: 15%;
        /*margin-top: 0;*/
    }
}

#contactForm button:active {
    background-color: #fff;
    color: #31589B;
}

.contactContent {
    width: 100%;
    margin: 0 auto;
}

.centre {
    text-align: center;
}